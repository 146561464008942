.profileContainer {
    width: 70%;
    background-color: #f3f3f3;
    border-radius: 28px;
    overflow: hidden;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    margin: 20px auto;
    font-family: Arial, sans-serif;
}

.profileHeader {
    display: flex;
    align-items: center;
    padding: 30px;
    background-size: cover;
    background-position: center;
}

.userInfo {
    margin-left: 20px;
    font-size: 16px;
    color: #333;
    line-height: 1.6;
}

.username {
    margin-top: 0;
    margin-bottom: 10px;
    color: #000;
    font-weight: bold;
    font-size: 24px;
}

.userInfo p {
    margin: 8px 0;
}

.userInfo p strong {
    color: #555;
    margin-right: 5px;
}

.avatar {
    width: 100px;
    height: 100px;
    background-color: #ddd;
    border-radius: 50%;
    border: 5px solid #fff;
    background-image: url('../../../assets/user-profile.png');
    background-size: cover;
    background-position: center;
}
.collectionName {
    font-size: 24px;
    font-weight: bold;
    color: #f39c12;
    margin: 0;
    text-transform: uppercase;
    font-family: "Montserrat", sans-serif !Important;
}
.exportButtonContainer {
    margin-top: 100px;
    margin-left: auto;
    position: relative;
    top: 0;
    left: 0;
}
.exportButton {
    background-color: #0056a3;
    color: white;
    border: none;
    padding: 10px 20px;
    font-size: 16px;
    font-weight: bold;
    border-radius: 25px;
    cursor: pointer;
    transition: background-color 0.3s ease;
    display: flex;
    align-items: center;
    gap: 8px;
}

.exportButton:hover {
    background-color: #e67e22;
}

.statsContainer {
    display: flex;
    justify-content: space-around;
    padding: 20px;
    background-color: white;
    border-radius: 22px;
    margin-top: 20px;
}

.stats {
    text-align: center;
    font-family: "Montserrat", sans-serif !Important;
}

.stats span:first-child {
    display: block;
    font-size: 16px;
    font-weight: bold;
}

.stats span:last-child {
    display: block;
    font-size: 14px;
    color: #888;
}
.cardListClass {
    display: flex;
    flex-wrap: wrap;      /* Permette di andare a capo se necessario */
    gap: 20px;            /* Spaziatura orizzontale/verticale tra gli elementi */
    justify-content: flex-start;
    align-items: flex-start;
}

.cardListClass > * {
    flex: 0 0 auto;       /* Mantiene la dimensione del contenuto, senza espandersi */
    width: 200px;         /* Larghezza fissa o massima dell'elemento */
}
.disabledButton {
    background-color: #ccc !important;
    color: #666 !important;
    cursor: not-allowed !important;
}
