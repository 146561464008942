.chartContainer {
    width: 70%;
    background-color: #2c3e50;
    color: white;
    padding: 20px;
    border-radius: 28px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    margin-bottom: 20px;
    margin: 20px auto;
    position: relative;
}

h2 {
    text-align: center;
    color: #ecf0f1;
    margin-bottom: 20px;
}


.buttonContainer {
    position: absolute;
    top: 80%;
    left: 50%;
    transform: translateX(-50%);
    z-index: 10;
    display: flex;
    justify-content: center;
    gap: 15px;
    padding: 5px;
    flex-wrap: wrap;
}

.bottoni {
    background-color: #1abc9c;
    color: white;
    border: none;
    padding: 10px 20px;
    border-radius: 25px;
    font-family: "Montserrat", sans-serif !important;
    font-weight: bold;
    box-shadow: 0 4px 16px rgba(0, 0, 0, 0.1);
    cursor: pointer;
    transition: background-color 0.3s ease;
    font-size: 14px;
}

.bottoni:hover {
    background-color: #16a085;
}
