.breadcrumb {
    display: flex;
    align-items: center;
    font-size: 17px;
    font-weight: 600;
    margin-left: 20px;
    margin-top: 25px;
    color: #333;
    padding: 10px 20px;
    border-radius: 5px;
    font-family: "Nunito", sans-serif !Important;
    margin-bottom: 40px;
}


.breadcrumbItem {
    color: #007bff;
    text-decoration: none;
    transition: color 0.3s ease;
}

.breadcrumbItem:hover {
    color: #0056b3;
    text-decoration: underline;
}

.breadcrumbSeparator {
    margin: 0 8px;
    color: #888;
    font-weight: 500;
}

.breadcrumbCurrent {
    color: #333;
    font-weight: 700;
}
