.ribbon {
    position: absolute;
    top: 0;
    left: 0;
    width: 100px;
    height: 30px;
    background-color: #1a1a1a; /* Colore del segnalibro */
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    border-bottom-right-radius: 15px; /* Arrotonda l'angolo */
    z-index: 2;
}

.ribbonText {
    font-size: 0.8rem;
    font-weight: bold;
    font-family: 'Montserrat', sans-serif;
    text-transform: uppercase;
}

.cardContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 20px;
}

.card {
    position: relative;
    width: 300px;
    height: 180px;
    border-radius: 15px;
    overflow: hidden;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
    transition: transform 0.3s ease, box-shadow 0.3s ease;
    background-color: #ffffff;
}

.card:hover {
    transform: scale(1.03);
    box-shadow: 0 8px 20px rgba(0, 0, 0, 0.2);
}

.cardOverlay {
    position: absolute;
    bottom: 0;
    width: 100%;
    background: rgba(0, 0, 0, 0.6);
    color: white;
    padding: 15px;
    text-align: center;
    box-sizing: border-box;
}

.cardTitle {
    font-size: 1.2rem;
    font-weight: bold;
    margin: 0 0 5px 0;
    color: white;
    text-shadow: 0 2px 4px rgba(0, 0, 0, 0.3);
    font-family: 'Montserrat', sans-serif;
}

.cardWants {
    font-size: 0.9rem;
    margin: 5px 0;
    color: #dcdcdc;
    font-family: 'Nunito', sans-serif;
}

.cardButton {
    display: inline-block;
    padding: 8px 15px;
    background-color: #4caf50;
    color: white;
    font-size: 0.7rem;
    font-weight: bold;
    text-decoration: none;
    border-radius: 20px;
    transition: background-color 0.3s ease, transform 0.2s ease;
    font-family: 'Nunito', sans-serif;
    margin-left: 10px;
    margin-top: 5px;
}

.cardButton:hover {
    background-color: #45a049;
    transform: translateY(-2px);
}

.chip {
    display: inline-block;
    /*background-color: #1a1a1a;*/
    color: white !important;
    padding: 6px 12px;
    border-radius: 16px;
    font-size: 15px;
    font-family: 'Nunito', sans-serif;
    text-align: center;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    font-weight: bold;
    font-style: italic;
}
