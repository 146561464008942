.card {
    border-radius: 20px;
    padding: 16px;
    text-align: center;
    transition: transform 0.3s ease, box-shadow 0.3s ease;
    background-color: transparent !important;
    position: relative;
    max-width: 200px;
    margin: 0 auto;
}

.card:hover {
    transform: scale(1.05);
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
}
.priceTag {
    font-family: "Nunito", sans-serif !important;
    font-weight: bold;
    position: absolute;
    top: 10px;
    left: 10px;
    background-color: rgba(0, 0, 0, 0.5);
    color: white;
    padding: 5px 12px;
    border-radius: 15px;
    font-size: 0.9rem;
    z-index: 10;
    cursor: pointer;
}
.cardImageContainer {
    position: relative;
    display: inline-block;
}

.cardImage {
    width: 100%;
    height: auto;
    max-width: 200px;
    border-radius: 8px;
    object-fit: cover;
    cursor: pointer;
}

.symbolsContainer {
    position: absolute;
    top: 10px;
    right: -30px;
    display: flex;
    flex-direction: column;
    gap: 5px;
}

.symbol {
    background-color: white;
    border: 1px solid black;
    border-radius: 50%;
    padding: 5px;
    text-align: center;
    font-size: 1em;
    color: #333;
    cursor: pointer;
    width: auto;
    height: 25px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.cardDetails {
    margin-top: 12px;
}

.cardTitle {
    margin: 0;
    font-size: 1.2em;
}

.pagination {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 16px;
    margin-top: 16px;
}

.pagination button {
    padding: 8px 16px;
    border: none;
    background-color: #007bff;
    color: white;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.pagination button:disabled {
    background-color: #ccc;
    cursor: not-allowed;
}

.pagination button:hover:not(:disabled) {
    background-color: #0056b3;
}

.add:hover {
    color: #0056a3;
    transform: scale(1.2);
}
