    .filtersContainer {
        display: flex;
        flex-wrap: wrap;
        gap: 1rem; /* Spazio tra gli elementi */
        justify-content: space-between;


}

.filtro{
    font-weight: bold;
}

.ricerca-span {
    font-size: 18px;
    font-weight: bold;
    color: #3f51b5;
    text-transform: uppercase;
    padding: 8px 12px;
    border-radius: 8px;
    background-color: #f1f1f1;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
    transition: background-color 0.3s ease, transform 0.3s ease;
}

.ricerca-span:hover {
    background-color: #e1e1e1;
    cursor: pointer;
    transform: scale(1.05);
}

.filterContainer {
    padding: 23px;
    margin: 20px auto;
    border-radius: 50px !important;
    background: linear-gradient(135deg, #f9f9f9, #eaeaea);
    box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.1);
    max-width: 800px;
    width: 100%;
    box-sizing: border-box;
}

.inputGroup {
    display: flex;
    flex-direction: column;
    gap: 16px;
}

    .menu-item {
        padding: 10px 15px;
        font-size: 16px;
    }

    .menu-item:hover {
        background-color: #007bff;
        color: #ffffff;
    }

    .menu-item.Mui-selected {
        background-color: #0056b3;
        color: #ffffff;
    }

    .menu-item.Mui-selected:hover {
        background-color: #003d80;
    }


@media (max-width: 768px) {
    .filterContainer {
        padding: 16px;
        margin: 10px auto;
        border-radius: 20px;
    }

    .inputGroup {
        gap: 12px;
    }

    .filterButton,
    .resetButton {
        font-size: 14px;
    }
}

@media (max-width: 480px) {
    .filterContainer {
        padding: 10px;
        margin: 5px auto;
        border-radius: 15px;
    }

    .ricercaSpan {
        font-size: 18px;
        text-align: center;
    }

    .filterButton,
    .resetButton {
        font-size: 12px;
        padding: 10px;
    }
}
