.profileContainer {
    width: 70%;
    background-color: #f3f3f3;
    border-radius: 28px;
    overflow: hidden;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    margin-bottom: 20px;
    margin: 20px auto;
}

.profileHeader {
    display: flex;
    align-items: center;
    padding: 20px;
    background-size: cover;
    background-position: center;
}

.avatar {
    width: 100px;
    height: 100px;
    background-color: #ddd;
    border-radius: 50%;
    border: 5px solid #fff;
    background-image: url('../assets/user-profile.png');
    background-size: cover;
    background-position: center;
}

.userInfo {
    margin-left: 20px;
}

.collectionName {
    font-size: 24px;
    font-weight: bold;
    background-color: #1a1a1a;
    color: #f39c12;
    border-radius: 14px;
    margin: 0;
    text-transform: uppercase;
    font-family: "Montserrat", sans-serif !Important;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    padding: 6px 12px;
    margin-bottom: 8px;
}

.username {
    margin-top: 10px;
    display: flex;
    align-items: center;
    color: black;
    font-weight: bold;
    font-size: 15px;
}

.flag {
    width: 24px;
    height: 24px;
    margin-left: 10px;
}

.statsContainer {
    display: flex;
    justify-content: space-around;
    padding: 20px;
    background-color: white;
    border-radius: 22px;
    margin-top: 20px;
}

.stats {
    text-align: center;
    font-family: "Montserrat", sans-serif !Important;
}

.stats span:first-child {
    display: block;
    font-size: 18px;
    font-weight: bold;
}

.stats span:last-child {
    display: block;
    font-size: 14px;
    color: #888;
}

.icon {
    margin-left: 10px;
    color: black;
    font-size: 20px;
}

.cardGridContainer {
    background-color: white;
    border-radius: 18px;
    box-shadow: 0 4px 9px rgba(0, 0, 0, 0.1);
    padding: 30px;
    margin-top: 20px;
}
.tabContainer {
    background-color: white;
    border-radius: 32px;
    box-shadow: 0 7px 9px rgba(0, 0, 0, 0.1);
    padding: 20px;
    margin-top: 20px;
    font-size: 16px;
    line-height: 1.5;
    max-width: 100%;
    box-sizing: border-box;
}



.exportButtonContainer {
    margin-top: 100px;
    margin-left: auto;
    position: relative;
    top: 0;
    left: 0;
}

.exportButton {
    background-color: coral;
    color: white;
    border: none;
    padding: 10px 20px;
    font-size: 16px;
    font-weight: bold;
    border-radius: 25px;
    cursor: pointer;
    transition: background-color 0.3s ease;
    display: flex;
    align-items: center;
    gap: 8px;
}

.exportButton:hover {
    background-color: #e67e22;
}

.commentSection {
    margin-top: 40px;
    padding: 20px;
    background-color: #f0f4f8;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    max-width: 800px;
    margin-left: auto;
    margin-right: auto;
}

.commentSection textarea {
    width: 100%;
    height: 100px;
    padding: 10px;
    border-radius: 5px;
    border: 1px solid #ddd;
    margin-bottom: 20px;
    font-family: 'Nunito', sans-serif;
    font-size: 16px;
    resize: none;
}
.commentSection {
    margin-top: 30px;
    padding: 20px;
    border-top: 1px solid #ccc;
    font-family: 'Nunito', sans-serif;
    font-style: italic;
    text-align: center;
    color: #3f51b5 !important;
}

.commentTitle {
    font-size: 1.5em;
    margin-bottom: 15px;
}

.changeBackgroundButtonContainer {
    display: flex;
    align-items: center;
    gap: 8px;
}

.selectImageButton {
    background-color: #e0e0e0;
    color: black;
    border: none;
    padding: 5px 8px;
    border-radius: 5px;
    cursor: pointer;
    font-size: 0.8em;
    transition: background-color 0.3s;
    margin-bottom: 10px;
}

.selectImageButton:hover {
    background-color: #d5d5d5;
}

.changeBackgroundButton {
    background-color: #007bff;
    position: absolute;
    top: 10px;
    right: 10px;
    color: white;
    display: flex;
    align-items: center;
    border: none;
    padding: 8px 12px;
    border-radius: 5px;
    cursor: pointer;
    font-size: 1em;
    transition: background-color 0.3s;
}

.changeBackgroundButton:hover {
    background-color: #0056b3;
}
.chip {
    margin: 8px;
    background-color: #f0f0f0;
}
.customButton {
    margin-right: 8px;
    margin-top:15px;
    padding: 6px 12px;
    font-size: 10px;
    border-radius: 12px;
    width: auto;
}
.searchContainer {
    margin-bottom: 16px;
    text-align: center;
}

.searchInput {
    width: 100%;
    max-width: 500px;
    padding: 12px 16px;
    font-size: 18px;
    border-radius: 8px;
    border: 1px solid #ddd;
    background-color: #f9f9f9;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    transition: all 0.3s ease;
}

.searchInput:focus {
    border-color: #007bff;
    outline: none;
    background-color: #ffffff;
    box-shadow: 0 0 8px rgba(0, 123, 255, 0.2);
}
