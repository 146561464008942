.loading {
    text-align: center;
    font-size: 1.5em;
    padding: 20px;
}

.error {
    text-align: center;
    color: red;
    font-size: 1.5em;
    padding: 20px;
}

.header {
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
}

.titoloCollection {
    font-size: 2em;
    padding: 20px;
    margin-bottom: 20px !important;
    font-family: "Nunito", sans-serif !important;
    font-weight: bold;
    text-transform: uppercase;
}

.createButton {
    position: absolute;
    right: 0;
}

.createButton:hover {
    background-color: #0056b3;
}

.stilecard{
    padding: 20px;
    margin-top: 20px;
    margin-bottom: 50px;
}

.styledcard{
    margin-bottom: 20px;
}
.contenutoCard{
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 20px;
}
.titolo{
    font-weight: bold;
    font-size: 2em;
    font-family: "Montserrat", sans-serif;
    text-transform: uppercase;
    margin-right: 20px;
    display: flex;
    align-items: center;
}
.icona{
    margin-right: 30px;
    font-size: 1.7em;
}
