.tcg-header {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 3px;
    width: 100%;
}

.tcg-header-image {
    max-width: 30%;
    height: 10%;
}
