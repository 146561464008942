.commentListContainer {
    margin-top: 20px;
    padding: 20px;
    background-color: #f0f4f8;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    max-width: 800px;
    margin-left: auto;
    margin-right: auto;
}

.commentItem {
    margin-bottom: 20px;
    padding: 15px;
    background-color: #fff;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.commentHeader {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 10px;
}

.username {
    font-style: italic;
    font-size: 0.9em;
    color: #333;
    font-family: "Montserrat", sans-serif !Important;
}

.rating {
    display: flex;
}

.commentText {
    font-size: 1em;
    color: #555;
    margin: 0;
    line-height: 1.5;
    font-family: "Montserrat", sans-serif !Important;
}
.commentItem {
    position: relative;
    padding: 20px;
    border: 1px solid #ddd;
    border-radius: 8px;
    margin-bottom: 20px;
    background-color: #f9f9f9;
}

.actionIcons {
    position: absolute;
    bottom: 10px;
    right: 10px;
    display: flex;
    gap: 10px;
}

.icon {
    cursor: pointer;
    color: gray;
    transition: color 0.3s ease;
}

.icon:hover {
    color: #555;
}
.editRating {
    display: flex;
    justify-content: flex-start;
    margin-top: 10px;
}

.rating {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    cursor: pointer;
}

.commentEditInput {
    width: 100%;
    height: 120px;
    padding: 10px;
    border-radius: 4px;
    border: 1px solid #ccc;
    font-size: 14px;
    resize: vertical;
}
.editRating {
    display: flex;
    align-items: center;
    margin-top: 10px;
}

.editRatingText {
    font-size: 16px;
    font-weight: bold;
    margin-right: 10px;
    color: #333;
}

.rating {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    cursor: pointer;
}

.commentEditInput {
    width: 100%;
    height: 120px;
    padding: 10px;
    border-radius: 4px;
    border: 1px solid #ccc;
    font-size: 14px;
    resize: vertical;
}

.commentono {
    margin-top: 25px;
    font-family: "Montserrat", sans-serif;
    font-size: 23px;
    color: #A9A9A9;
    margin-bottom: 30px;

}
