.blocker {
    display: inline-block;
    color: white;
    padding: 2px;
    background-color: orange;
    border-radius: 10px;
    border: 3px solid orange;
    margin-bottom: 5px;
    margin-right: 5px;
    margin-left: 5px;
}

.on-ko {
    background-color: #1E88E5;
    color: white;
    padding: 2px;
    border-radius: 10px;
    border: 3px solid #1E88E5;
    margin-bottom: 25px;
    margin-right: 5px;
    margin-left: 5px;
}

.trigger {
    background-color: yellow;
    color: black;
    font-weight: bold;
    padding: 2px;
    border-radius: 10px;
    border: 3px solid yellow;
    margin-right: 5px;
    margin-left: 5px;
    margin-bottom: 5px;
}

.counter {
    background-color: red;
    color: white;
    font-weight: bold;
    padding: 2px;
    border-radius: 10px;
    border: 3px solid red;
    margin-bottom: 5px;
    margin-right: 5px;
    margin-left: 5px;
}

.once {
    background-color: mediumvioletred;
    color: white;
    font-weight: bold;
    padding: 2px;
    border-radius: 10px;
    border: 3px solid mediumvioletred;
    margin-bottom: 5px;
    margin-right: 5px;
    margin-left: 5px;
}

.don-number {
    background-color: aquamarine !important;
    color: white;
    font-weight: bold;
    padding: 2px;
    border-radius: 10px;
    border: 3px solid black;
    margin-bottom: 5px;
    margin-right: 5px;
    margin-left: 5px;
}
