.dialogPaper {
    background: transparent !important;
    box-shadow: none;
    overflow: hidden !important;
    color: white;
    position: relative;
}

.dialogBackdrop {
    background-color: rgba(0, 0, 0, 0.9) !important;
}

.header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px 20px;
    background-color: transparent !important;
}

.cardName {
    margin: 0;
    font-size: 1.1em;
    text-align: center;
    flex: 1;
    color: white;
    font-family: 'Nunito', sans-serif !Important;
    font-weight: bold;
}
.cardWrapper {
    background-color: #1b2a47;
    padding: 8px;
    border-radius: 18px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    text-align: center;
}

.closeButton {
    color: white !important;
    font-size: 1.5em !important;
    position: absolute;
    right: 20px;
}

.dialogContent {
    overflow-y: hidden !important;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    padding: 20px;
}

.imageContainer {
    display: flex;
    justify-content: center;
    align-items: center;
}

.dialogImage {
    width: 100%;
    max-width: 330px;
    border-radius: 8px;
}

.detailsContainer {
    padding: 20px;
}

.centeredTable {
    margin: 10% auto !important;
    width: auto;
    max-width: 500px;
}
.uppercase{
    font-size: 18px !important;
}

.detailsTable {
    width: 100%;
    margin-top: 20px;
}

.detailsTable th,
.detailsTable td {
    padding: 10px;
    border-bottom: 1px solid #555;
    text-align: center;
}

.detailsTable th {
    text-align: left;
    color: #f39c12;
    font-family: 'Edu AU VIC WA NT Hand', cursive;
    text-transform: capitalize;
}

.detailsTable td {
    font-weight: bold;
    font-family: 'Nunito, sans-serif' !important;
    color: #ddd;
    text-transform: capitalize;
}

.alternativeArtIcon {
    color: gold;
    margin-left: 8px;
    font-size: 1em;
}

.blocker {
    display: inline-block;
    color: white;
    padding: 2px;
    background-color: orange;
    border-radius: 10px;
    border: 3px solid orange;
    margin-bottom: 5px;
    margin-right: 5px;
    margin-left: 5px;
}

.on-ko {
    background-color: #1E88E5;
    color: white;
    padding: 2px;
    border-radius: 10px;
    border: 3px solid #1E88E5;
    margin-bottom: 25px;
    margin-right: 5px;
    margin-left: 5px;
}

.trigger {
    background-color: yellow;
    color: black;
    font-weight: bold;
    padding: 2px;
    border-radius: 10px;
    border: 3px solid yellow;
    margin-right: 5px;
    margin-left: 5px;
    margin-bottom: 5px;
}

.counter {
    background-color: red;
    color: white;
    font-weight: bold;
    padding: 2px;
    border-radius: 10px;
    border: 3px solid red;
    margin-bottom: 5px;
    margin-right: 5px;
    margin-left: 5px;
}

.once {
    background-color: mediumvioletred;
    color: white;
    font-weight: bold;
    padding: 2px;
    border-radius: 10px;
    border: 3px solid mediumvioletred;
    margin-bottom: 5px;
    margin-right: 5px;
    margin-left: 5px;
}

.don-number {
    background-color: black !important;
    color: white;
    font-weight: bold;
    padding: 2px;
    border-radius: 10px;
    border: 3px solid black;
    margin-bottom: 5px;
    margin-right: 5px;
    margin-left: 5px;
}


.chip {
    background-color: #3e4c63 !important;
    color: white !important;
    font-family: 'Montserrat, sans-serif' !important;
    margin: 4px;
    border-radius: 16px;
    font-weight: bold;
    padding: 8px 12px;
    transition: background-color 0.3s;
    font-size: 18px !important;
}

.chip:hover{
    background-color: #0056a3 !important;
    cursor: pointer;
}

.flipped {
    transform: rotateY(180deg);
    transition: transform 0.6s;
}

.flipButton {
    background: linear-gradient(45deg, #4CAF50 30%, #81C784 90%);
    color: white;
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    font-size: 16px;
    cursor: pointer;
    transition: transform 0.3s, background 0.3s;
    display: flex;
    align-items: center;
    margin-left: 10px;
    width: 50%;
}

.flipButton:hover {
    background: linear-gradient(45deg, #388E3C 30%, #66BB6A 90%);
    transform: scale(1.05);
}

.flippedButton {
    background: linear-gradient(45deg, #D32F2F 30%, #E57373 90%);
}

.flippedButton:hover {
    background: linear-gradient(45deg, #C62828 30%, #EF9A9A 90%);
}
.rotating {
    transform: rotateY(180deg);
    transition: transform 0.6s;
}
.aforisma{
    font-style: italic;
}
.cardContainer {
    margin-bottom: 16px;
}

.cardHeader {
    background-color: #f5f5f5;
    padding: 16px;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    text-align: center;
}

.detailsTable {
    width: 100%;
    border-collapse: collapse;
}

.detailsTable th, .detailsTable td {
    padding: 8px;
    text-align: left;
    border-bottom: 1px solid #ddd;
}

.rowDivider td {
    border-bottom: 2px solid #bbb;
}
