.cardList {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 12px;
    margin: 0 auto;
    padding: 20px;
    max-width: 800px;
}

.cardList > * {
    flex-grow: 1;
    flex-basis: calc(33.33% - 12px);
    box-sizing: border-box;
}

@media (max-width: 768px) {
    .cardList > * {
        flex-basis: calc(50% - 12px);
    }
}

@media (max-width: 480px) {
    .cardList > * {
        flex-basis: calc(100% - 12px);
    }
}
