.footerContainer {
    background-color: #1b2a47;
    color: white;
    padding: 40px 20px;
    font-family: Arial, sans-serif;
    bottom: 0;
}

.logoContainer {
    display: flex;
    justify-content: center;
    margin-bottom: 20px;
}

.logo {
    max-width: 300px;
    height: auto;
}

.footerTop {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    margin-bottom: 40px;
}

.footerColumn {
    flex: 1;
    min-width: 200px;
    margin: 10px;
}

.footerColumn h4 {
    margin-bottom: 10px;
    font-size: 1.2em;
}

.footerColumn ul {
    list-style: none;
    padding: 0;
}

.footerColumn ul li {
    margin-bottom: 5px;
}

.footerColumn ul li a {
    color: white;
    text-decoration: none;
    transition: color 0.3s;
}

.footerColumn ul li a:hover {
    color: #0099ff;
}

.footerBottom {
    display: flex;
    justify-content: center;
    border-top: 1px solid #3e4c63;
    padding-top: 20px;
    font-size: 0.9em;
}

.footerBottom span {
    color: #ccc;
}

.socialIcons {
    display: flex;
    justify-content: center;
    margin-top: 20px;
}

.socialIcons a {
    margin: 0 10px;
    color: white;
    font-size: 1.5em;
    transition: color 0.3s;
}

.socialIcons a:hover {
    color: #0099ff;
}

.nomicolonne {
    color: coral;
    font-weight: bold;
    font-family: 'Roboto', sans-serif;
    font-size: 1.5em !important;
}

.nomivoci {
    font-family: "Nunito", sans-serif !Important;
    font-weight: bold;
}

.footerBottom {
    display: flex;
    flex-direction: column;
    align-items: center;
    border-top: 1px solid #3e4c63;
    padding-top: 20px;
    font-size: 0.9em;
}

.footerBottom span {
    color: #ccc;
}

.subtitles {
    color: grey;
    font-style: italic;
    font-size: 15px;
}
