.commentAndRateContainer {
    margin: 0 auto;
    padding: 20px;
    background-color: #f9f9f9;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.form {
    display: flex;
    flex-direction: column;
}

.stars {
    display: flex;
    justify-content: center;
    margin-bottom: 20px;
}

.radioInput {
    display: none;
}

.star {
    cursor: pointer;
    transition: color 0.2s;
}

.textarea {
    width: 100%;
    height: 100px;
    padding: 10px;
    border-radius: 5px;
    border: 1px solid #ddd;
    margin-bottom: 20px;
    font-family: "Montserrat", sans-serif !Important;
    font-size: 16px;
}

.submitButton {
    padding: 10px 20px;
    background-color: #007bff;
    color: white;
    border: none;
    border-radius: 25px;
    cursor: pointer;
    transition: background-color 0.2s;
    font-family: "Montserrat", sans-serif !Important;
    font-size: 20px;
    font-weight: bold;
}

.submitButton:hover {
    background-color: #0056b3;
}
