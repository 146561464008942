.cards-page {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px;
    margin-top: 20px;
}

.filters {
    flex: 1;
    max-width: 400px;
    margin: 0 20px;
}

.card-list-container {
    flex: 3;
    width: 100%;
}

.cardList {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 16px;
}

.loading-spinner {
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 200px;
}

.MuiPagination-root {
    margin-top: 20px;
    display: flex;
    justify-content: center;
}

.content {
    display: flex;
    width: 100%;
    justify-content: space-between;
}
