.cards-page {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px;
    width: 100%;
    box-sizing: border-box;
}

.content {
    display: grid;
    grid-template-columns: 1fr 3fr;
    gap: 20px;
    width: 100%;
    align-items: flex-start;
    box-sizing: border-box;
    margin: 0 auto;
}

.filters {
    width: 100%;
    margin: 0;
    box-sizing: border-box;
}

.card-list-container {
    width: 100%;
    box-sizing: border-box;
    padding: 0 10px;
}

.cardList {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    gap: 16px;
    padding: 10px 0;
    box-sizing: border-box;
}

.cardList > * {
    flex-basis: calc(33.33% - 16px);
}

.view-buttons {
    display: flex;
    justify-content: flex-end;
    gap: 10px;
    margin-bottom: 10px;
}

.view-button {
    padding: 10px 20px;
    border: 1px solid #ccc;
    border-radius: 5px;
    background-color: #f4f4f4;
    color: #333;
    font-size: 14px;
    font-weight: bold;
    cursor: pointer;
    transition: all 0.3s ease;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.view-button:hover {
    background-color: #ddd;
}

.view-button.selected {
    background-color: #3f51b5;
    color: #fff;
    border-color: #3f51b5;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    cursor: default;
}

@media (max-width: 1024px) {
    .cardList > * {
        flex-basis: calc(50% - 16px);
    }
}

@media (max-width: 768px) {
    .content {
        grid-template-columns: 1fr;
        gap: 20px;
    }

    .filters {
        width: 100%;
        margin-bottom: 20px;
    }

    .cardList {
        flex-direction: column;
        align-items: center;
    }

    .cardList > * {
        flex-basis: 100%;
        max-width: 100%;
    }
}
