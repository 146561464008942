.cardList {
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
    justify-content: center;
    padding: 20px;
}
    .cardTable {
        width: 100%;
        border-collapse: collapse;
        margin-top: 20px;
    }

    .cardTable th {
        text-align: left;
        padding: 10px;
        font-weight: bold;
        color: #333;
        background-color: #f4f4f4;
        border-bottom: 2px solid #ddd;
    }

    .cardTable td {
        padding: 10px;
        color: #555;
        text-align: center;
        vertical-align: middle;
        transition: background-color 0.3s;
    }

    .cardTable tr:nth-child(even) {
        background-color: #f9f9f9;
    }

    .cardTable tr:hover {
        background-color: #f1f1f1;
    }

.actionIcons svg {
    cursor: pointer;
    transition: color 0.2s ease;
}

.actionIcons svg:hover {
    color: #3f51b5; /* Colore al passaggio del mouse */
}
table {
    width: 100%;
    border-collapse: collapse;
}
