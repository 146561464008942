/* HorizontalMenu.module.css */
.container {
    display: flex;
    flex-direction: column;
    background-color: #1b2a47 !important;
    color: white !important;
    font-family: "Nunito", Arial, sans-serif;
}

.topBar {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 15px 25px;
    background-color: #1b2a47 !important;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.5) !important;
}

.logo {
    font-size: 1.8em;
    font-weight: bold;
    display: flex;
    align-items: center;
}

.logoImage {
    height: 60px;
    margin-right: 15px;
    object-fit: contain;
    max-width: 100%;
    height: auto;
}

.searchBar {
    flex-grow: 1;
    margin: 0 20px;
}

.searchInput .MuiOutlinedInput-notchedOutline {
    border-color: white !important;
    transition: border-color 0.3s ease;
}

.searchInput:hover .MuiOutlinedInput-notchedOutline,
.searchInput.Mui-focused .MuiOutlinedInput-notchedOutline {
    border-color: #ffcc00 !important;
}

.iconsContainer {
    display: flex;
    gap: 16px;
    align-items: center;
}

.icon {
    display: flex;
    font-size: 1.5em;
    cursor: pointer;
    position: relative;
    transition: color 0.3s ease, transform 0.3s ease;
    color: white !important;
    align-items: center;
    justify-content: center;
}

.icon:hover {
    color: #ffcc00 !important;
    transform: scale(1.2);
}

.price {
    position: absolute;
    top: -10px;
    right: -10px;
    background-color: #ffcc00 !important;
    color: #000 !important;
    padding: 4px 6px;
    border-radius: 50%;
    font-size: 0.8em;
}

.bottomBar {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 15px 25px;
    background-color: #243b63 !important;
    border-top: 2px solid rgba(255, 255, 255, 0.2) !important;
}

.menu {
    display: flex;
    gap: 25px;
}

.menuItem {
    text-decoration: none;
    color: white !important;
    cursor: pointer;
    transition: background-color 0.3s ease, color 0.3s ease;
    padding: 8px 12px;
    border-radius: 8px;
}

.menuItem:hover {
    background-color: rgba(250, 255, 255, 0.1) !important;
    color: #ffcc00 !important;
}

.active {
    border-bottom: 2px solid #ffcc00 !important;
    color: #ffcc00 !important;
}

.buttonGroup {
    display: flex;
    gap: 22px;
}

.gameLogo {
    width: 100%; /* Rendi l'immagine responsive */
    max-width: 100px; /* Imposta una larghezza massima per non allungare troppo */
    height: auto;
    margin-right: 10px;
    object-fit: contain;
}

.button {
    font-weight: bold;
    border-radius: 12px;
    background-color: #0066ff !important;
    color: white !important;
    transition: background-color 0.3s ease, box-shadow 0.3s ease !important;
}

.button:hover {
    background-color: #0056cc !important;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.5) !important;
}

.myCollectionbtn,
.publicbtn,
.marketbtn {
    color: white !important;
    padding: 10px 25px;
    text-transform: uppercase;
    border-radius: 50px !important;
    font-weight: bold !important;
    font-size: 1em !important;
    transition: background-color 0.3s ease, box-shadow 0.3s ease !important;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.5) !important;
}

.myCollectionbtn {
    background-color: green !important;
}

.myCollectionbtn:hover {
    background-color: brown !important;
}

.publicbtn {
    background-color: #3f51b5 !important;
}

.publicbtn:hover {
    background-color: cadetblue !important;
}

.marketbtn {
    background-color: #f39c12 !important;
}

.marketbtn:hover {
    background-color: coral !important;
}

/* Menu hamburger e visibilità per dispositivi mobili */
.hamburgerIcon {
    display: none;
}

/* Regole per dispositivi mobili */
@media (max-width: 768px) {
    .hamburgerIcon {
        display: block;
    }

    .menu {
        display: none; /* Menu nascosto di default */
    }

    .menuOpen {
        display: block;
        position: absolute;
        top: 0;
        left: 0;
        background: white !important;
        width: 100%;
        padding: 10px;
        z-index: 1000;
    }

    .menuItem {
        display: block;
        margin: 10px 0;
    }


    .menuOpen .menuItem {
        color: black;
    }
}
/* -------ICONE LOGIN & REGISTRATI--------*/

.authButtons {
    display: flex;
    gap: 15px;
}

.loginButton,
.registerButton {

    font-weight: bold;
    padding: 8px 16px;
    border-radius: 25px;
    text-transform: uppercase;
    font-size: 1em;
    transition: background-color 0.3s ease, transform 0.3s ease, box-shadow 0.3s ease, color 0.3s ease;
    display: flex;
    align-items: center;
    justify-content: center;
}

.loginButton {
    background: linear-gradient(90deg, #0066ff, #0056cc);
    color: white !important;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);
}

.loginButton:hover {
    background: linear-gradient(90deg, #0056cc, #0046aa);
    transform: scale(1.05);
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.5);
}

.loginButton:hover svg {
    color: #ffcc00;
}

.registerButton {
    background: linear-gradient(90deg, #28a745, #218838);
    color: white !important;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);
}

.registerButton:hover {
    background: linear-gradient(90deg, #218838, #1e7e34);
    transform: scale(1.05);
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.5);
}

.registerButton:hover svg {
    color: #ffcc00;
}

.loginButton svg,
.registerButton svg {
    font-size: 1.5em;
    transition: color 0.3s ease;
}
.menu {
    font-family: 'Roboto', sans-serif;
}

.menu-header {
    padding: 8px 16px;
    font-weight: bold;
    color: #333;
    border-bottom: 1px solid #eee;
}

.menu-item {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 6px;
    padding: 12px 16px;
    border-bottom: 1px solid #f0f0f0;
}

.menu-avatar {
    margin-right: 10px;
    background-color: #3f51b5;
    width: 36px;
    height: 36px;
}

.menu-buttons {
    display: flex;
    justify-content: space-between;
    width: 100%;
}

.menu-button-accept {
    background-color: #4caf50;
    color: white;
    font-size: 10px;
    padding: 4px 8px;
    text-transform: capitalize;
}

.menu-button-reject {
    font-size: 10px;
    padding: 4px 8px;
    text-transform: capitalize;
    color: #f44336;
    border: 1px solid #f44336;
}
.menu-footer {
    text-align: center;
    padding: 8px 0;
    border-top: 1px solid #eee;
}

.menu-footer button {
    color: #3f51b5;
    text-transform: capitalize;
    font-weight: bold;
    font-size: 12px;
    background: none;
    border: none;
    cursor: pointer;
}

.menu-footer button:hover {
    color: #303f9f;
}
.tooltipContainer {
    position: relative;
    display: inline-block;
}

.tooltipText {
    visibility: hidden;
    background-color: black;
    color: #fff;
    text-align: center;
    border-radius: 15px;
    padding: 10px;
    font-size: 13px;
    position: absolute;
    bottom: 125%;
    left: 50%;
    transform: translateX(-50%);
    opacity: 0;
    transition: opacity 0.3s ease;
    z-index: 1;
    font-weight: bold;
}

.tooltipContainer:hover .tooltipText {
    visibility: visible;
    opacity: 1;
}

.pulsanteDisabilitato {
    cursor: not-allowed !important;
    color: gray;
    opacity: 0.6;
    position: relative;
}
