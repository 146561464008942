.dialogPaper {
    background: rgba(255, 255, 255, 0.9) !important;
    box-shadow: 0 4px 20px rgba(0, 0, 0, 0.2);
    border-radius: 25px;
    overflow: hidden;
    color: #333;
    position: relative;
}

.dialogBackdrop {
    background-color: rgba(0, 0, 0, 0.9) !important;
    border-radius: 25px !important;
}

.header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px 20px;
    background-color: transparent !important;
}

.dialogContent {
    padding: 16px;
    color: white;
    font-family: "Nunito", sans-serif !Important;
}

.cardName {
    font-size: 1.9em;
    text-align: center;
    flex: 1;
    color: #1b2a47;
    font-family: "Nunito", sans-serif !Important;
    text-transform: uppercase;
    margin-top: 2%;
}

.formControlLabel {
    margin: 16px 0;
    color: #333;
}

.select {
    margin-bottom: 16px;
    border-radius: 28px !important;
}
.textField{
    border-radius: 28px !important;
}
