/* styles.css */

.bottone-home {
    margin-top: 20px;
    background-color: #ff4081;
    color: white;
    font-weight: 600;
    border-radius: 50px;
    padding: 10px 30px;
}

.bottone-home:hover {
    background-color: #e91e63;
}

.card-home {
    max-width: 345px;
    margin: 20px;
    transition: transform 0.3s, box-shadow 0.3s;
}

.card-home:hover {
    transform: scale(1.05);
    box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.15);
}
