.login-page {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    background: #f7f7f7;
}

.login-container {
    background: #fff;
    padding: 40px;
    border-radius: 40px;
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.1);
    width: 100%;
    max-width: 400px;
    text-align: center;
}

.login-container h2 {
    margin-bottom: 20px;
    font-size: 24px;
    color: #333;
    font-family: "Montserrat", sans-serif !Important;
    text-transform: uppercase;
}

.login-container label {
    display: block;
    text-align: left;
    margin-bottom: 8px;
    color: #555;
    font-family: "Montserrat", sans-serif !Important;
    text-transform: uppercase;
}

.login-container input {
    width: 100%;
    padding: 10px;
    margin-bottom: 20px;
    border: 1px solid #ddd;
    border-radius: 5px;
    box-sizing: border-box;
    font-size: 16px;
    color: #333;
}

.login-container input:focus {
    border-color: #007bff;
    outline: none;
}

.login-container button {
    width: 100%;
    padding: 10px;
    border: none;
    border-radius: 30px;
    background-color: #007bff;
    color: white;
    font-size: 20px;
    cursor: pointer;
    text-transform: uppercase;
    font-family: "Nunito", sans-serif !Important;
    font-weight: bold;
}

.login-container button:hover {
    background-color: #0056b3;
}

.login-container .error {
    color: red;
    margin-bottom: 20px;
}
